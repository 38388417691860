import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["submitForm"]

  submit(e){
    e.preventDefault();
    this.submitFormTarget.submit();
  }

  submit2(e) {
    e.preventDefault();
    document.getElementsByClassName('submitForm')[0].submit()
  }
}
