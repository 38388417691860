import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    pdfData: String,
    firstLastName: String,
    birthday: String,
    policy: String,
    city: String,
    fileId: String,
    fileType: String,
    fileName: String
  }

  static targets = [ 'modalForm', 'downloadAllButton', 'deleteAllButton', 'searchForm']

  initialize() {
    this.fileId = ''
  }

  requestPdvDetails(e){
    let interlId = e.target.dataset.component
    let url = 'document_management/' + interlId + '/request_internal_data';
    fetch(url.toString(), { method: "POST" })
      .then(response => response.json())
    this.getPdvDetails(interlId)
  }

  getPdvDetails(interlId) {
    if (this.detailsInterval) {
      clearInterval(this.detailsInterval);
    }

    let attempts = 0;
    const maxAttempts = 20;

    this.detailsInterval = setInterval(() => {
      if (attempts >= maxAttempts) {
        clearInterval(this.detailsInterval);
        document.querySelector('.spinner').classList.add('d-none')
        return;
      }

      let url = 'document_management/' + interlId + '/fetch_internal_data';
      fetch(url.toString(), { method: "GET" })
        .then(response => response.text())
        .then(html => {
          if (html !== '') {
            clearInterval(this.detailsInterval);
            this.updatePageResults(html, '#pdv-details');
          } else {
            attempts++;
          }
        })
    }, 1000);
  }

  submitFileUploadForm(e) {
    e.preventDefault();
    e.stopPropagation();

    e.target.disabled = true

    const form = document.querySelector('#upload-files-form')
    const url = new URL(form.action)

    const formData = new FormData(form)

    fetch(url.toString(), { method: "PATCH", body: formData })
      .then(response => response.text())
      .then(html => {
        this.updatePageResults(html, '#dolab-files-result');
      });
  }

  submitMappingForm(e) {
    e.preventDefault();
    e.stopPropagation();

    const form = document.querySelector('#mapping-form')
    const url = new URL(form.action)

    url.search = new URLSearchParams(new FormData(form)).toString();
    fetch(url.toString(), { method: "PATCH" })
      .then(response => response.text())
      .then(html => {
        this.updatePageResults(html, '#dolab-files-result');
        history.replaceState({}, document.title, window.location.pathname);
      });

    this.closeFileMappingModal(e)
  }

  deleteRecord(e) {
    e.preventDefault();
    e.stopPropagation();

    const link = e.target.closest('a')
    if (confirm(link.dataset.confirm)) {

      const url = new URL(link, window.location.href)
      fetch(url.toString(), { method: "DELETE" })
      .then(response => response.text())
      .then(html => {
        this.updatePageResults(html, '#dolab-files-result');
        history.replaceState({}, document.title, window.location.pathname);
      });
    }
  }

  updatePageResults(html, selector) {
    const element = document.querySelector(selector);
    element.innerHTML = html
  }

  showSearchStartNotification() {
    document.querySelector('#search-results-table').classList.add('d-none')
    document.querySelector('.search-start').classList.remove('d-none')
    document.querySelector('.search-start').classList.add('d-flex')
  }

  loadFile(e) {
    const data = e.currentTarget.dataset
    this.fileId = data.fileId

    this.updateIframeSource(data)
    this.changeFileBackground(e)
    this.prefilFileData(data)
    this.prefilModalFormData(data)
  }

  updateIframeSource(data) {
    this.showIframeAndButton()
    const iframe = document.getElementById('pdf-iframe')
    iframe.src = data.pdfData;
    if (!data.pdfData.toLowerCase().includes(".pdf")) {
      setTimeout(() => {
        iframe.src = '';
      }, 200)
    }
  }

  prefilFileData(data) {
    this.prefillSearchParams(data)

    const searchValues = [data.firstLastName, data.birthday, data.policy, data.city]

    let anyValuePresent = searchValues.some(value => value.trim() !== '')
    if (anyValuePresent) {this.submitSearchForm() }
  }

  prefilModalFormData() {
    this.modalFormTarget.querySelector('#file_id').value = this.fileId
    this.modalFormTarget.querySelector('#file_type').value = this.fileTypeValue;
    this.modalFormTarget.querySelector('#file_type').dispatchEvent(new Event('change'));
    this.modalFormTarget.querySelector('#file_name').value = this.fileNameValue;
  }

  changeFileBackground(e) {
    const pdfLinksContainers = document.querySelectorAll('.parent-div');
    const pdfLinks = document.querySelectorAll('.file-link');

    pdfLinksContainers.forEach(container => {
      container.style.backgroundColor = '#FFFFFF'
    });

    pdfLinks.forEach(link => {
      link.classList.add('text-dark')
      link.closest(".parent-div").querySelectorAll('.bullet-bar')[0].classList.add('d-none')
    });

    e.target.closest(".parent-div").querySelectorAll('.bullet-bar')[0].classList.remove('d-none')
    e.target.closest(".parent-div").style.backgroundColor = '#F3F6F9'
    e.target.classList.remove('text-dark')
    e.target.classList.add('text-primary')
  }

  // TODO: change this to do with turbo in the future
  openFileMappingModal(e) {
    this.hideAllTableRows(e.target.dataset.rowIndex);
    e.preventDefault();
    this.prefillModalData(e);
    document.getElementById('file-mapping').classList.remove('d-none');
    this.requestPdvDetails(e)
  }

  hideAllTableRows(index, style){
    if (!style && index) { style = 'none' }
    const tbody = document.getElementById('search-results-table');
    const rows = tbody.querySelectorAll('tr');
    rows.forEach(row => {
      // Check if the row's data-row-index attribute is not equal to 1
      if (row.getAttribute('data-row-index') != index) {
        // Hide the row by setting its display property to 'none'
        row.style.display = style;
      }
    });
  }

  closeFileMappingModal(e) {
    e.preventDefault();
    this.modalFormTarget.classList.add("d-none");
    let spinnerHTML = "<div class='d-flex justify-content-center mt-6 mb-3'><div class='spinner spinner-track spinner-primary spinner-lg mr-15'></div><div>"
    this.updatePageResults(spinnerHTML, '#pdv-details')
    this.hideAllTableRows(null, '')
    clearInterval(this.detailsInterval);
  }

  prefillSearchParams(data) {
    this.searchFormTarget.querySelector('#file_id').value = this.fileId
    this.searchFormTarget.querySelector('#policy_holder_name').value = data.firstLastName
    this.searchFormTarget.querySelector('#birthdate').value = data.birthday
    this.searchFormTarget.querySelector('#policy_number').value = data.policy
    this.searchFormTarget.querySelector('#address').value = data.city
  }

  prefillModalData(e) {
    const searchParams = new URL(location.href).searchParams
    this.modalFormTarget.querySelector('#file_id').value = this.fileId
    this.modalFormTarget.querySelector('#query').value = searchParams.get('query');
    this.modalFormTarget.querySelector('#component').value = e.target.dataset.component;
    this.modalFormTarget.querySelector('#case_id').value = e.target.dataset.caseId;
  }

  prefilModalFormPolicy(policy_number) {
    const filePolicyNumberText = document.querySelector('div.file-mapping #file-policy-text')

    if (this.isNotBlank(policy_number)) {
      filePolicyNumberText.classList.remove('d-none')
      document.querySelector('div.file-mapping #file-policy-number').textContent = policy_number
    } else {
      filePolicyNumberText.classList.add('d-none')
    }
  }

  submitSearchForm() {
    document.getElementById('search-button').click()
  }

  isNotBlank(value) {
    return value !== null && value !== undefined && value.trim() !== ''
  }

  clearField(e) {
    e.target.parentElement.previousElementSibling.value = ''
    this.submitSearchForm()
  }

  toggleHistory() {
    document.getElementById('status-history-table').classList.toggle('d-none')
  }

  toggleFileUploadSection() {
    this.hideIframeAndButton()
  }

  hideIframeAndButton() {
    const toggleButton = document.getElementById('toggle-upload-button')
    const iframe = document.getElementById('pdf-iframe')
    const uploadFileSection = document.getElementById('drag-drop-files-section')
    iframe.classList.add('d-none')
    uploadFileSection.classList.remove('d-none')
    toggleButton.classList.add('d-none')
  }

  showIframeAndButton() {
    const toggleButton = document.getElementById('toggle-upload-button')
    const iframe = document.getElementById('pdf-iframe')
    const uploadFileSection = document.getElementById('drag-drop-files-section')
    const errorSection = document.getElementById('error_explanation')
    iframe.classList.remove('d-none')
    uploadFileSection.classList.add('d-none')
    toggleButton.classList.remove('d-none')
    if (errorSection) {
      errorSection.classList.add('d-none')
    }
  }

  setAllCheckboxes(e) {
    let checkboxes = Array.from(document.querySelectorAll('input[type="checkbox"]'));
    checkboxes = checkboxes.filter(checkbox => checkbox !== e.target);

    checkboxes.forEach(checkbox => {
      if(e.target.checked) {
        this.downloadAllButtonTarget.classList.remove('d-none')
        this.deleteAllButtonTarget.classList.remove('d-none')
        checkbox.checked = true
      } else {
        this.downloadAllButtonTarget.classList.add('d-none')
        this.deleteAllButtonTarget.classList.add('d-none')
        checkbox.checked = false
      }
    })
  }

  showDeleteDownloadButtons() {
    let checkboxes = Array.from(document.querySelectorAll('input[type="checkbox"]'));
    checkboxes = checkboxes.filter(checkbox => checkbox.checked);
    if(checkboxes.length >= 1) {
      this.downloadAllButtonTarget.classList.remove('d-none')
      this.deleteAllButtonTarget.classList.remove('d-none')
    } else {
      this.downloadAllButtonTarget.classList.add('d-none')
      this.deleteAllButtonTarget.classList.add('d-none')
    }
  }

  downloadDeleteFiles(e) {
    e.preventDefault()
    e.stopPropagation()

    let checkboxes = Array.from(document.querySelectorAll('input[type="checkbox"]'));
    checkboxes = checkboxes.filter(checkbox => checkbox.checked).map(checkbox => checkbox.value )

    const formData = new FormData();
    checkboxes.forEach(value => {
      formData.append('file_ids[]', value)
    })

    const method = e.target.getAttribute('data-method')

    const href = e.target.href

    fetch(href, {
      method: method,
      headers: {
        'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
      },
      body: formData
    })
    .then(response => {
      if (method === 'post') { return response.blob() }

      return response.text()
    })
    .then(data => {
      if (method === 'post') {
        const url = window.URL.createObjectURL(data);
        window.location.assign(url)
      } else {
        this.updatePageResults(data, '#dolab-files-result')
        history.replaceState({}, document.title, window.location.pathname)
      }
    });
  }
}
