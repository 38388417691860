import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  resize(){
    if (this.editor){
        setTimeout(() => {
          this.editor.layout();
        }, 200)
    }
  }
  connect() {
    const el = this.element;
    const editorLanguage = el.attributes.language.nodeValue;
    let value = "";

    if(el.dataset["valueElementId"]){
      value = document.getElementById(el.dataset["valueElementId"]).value;
    }

    if(editorLanguage.toLowerCase() === 'javascript') {
      try {
        value = window.js_beautify(value, {
          indent_size: 2,
          preserve_newlines: false,
          space_in_empty_paren: true,
          end_with_newline: true
        });
      } catch (error) {
        console.error("Error formatting JSON code:", error);
      }
    }
    console.log(value)

    const editor = codeEditor.editor.create(el, {
      value: value,
      language: editorLanguage,
      theme: "vs-dark",
      readOnly: false,
      minimap: {
        enabled: false
      }
    });

    this.editor = editor;

    editor.getModel().onDidChangeContent((event) => {
      let value = editor.getValue();
      if(el.dataset["valueElementId"]){
        document.getElementById(el.dataset["valueElementId"]).value = value;
      }
    });

    window.onresize = function () {
      editor.layout();
    };

    if (el.dataset["accordionElementId"]){
      document.getElementById(el.dataset["accordionElementId"]).onclick = function(){
        setTimeout(() => {
          editor.layout();
        }, 200)
      }
    }

    // function saveCode(id) {
    //     if (id === 'editor-json') {
    //         const jsModel = codeEditor.editor.getModels()[1];
    //         const jsonVal = sessionStorage.getItem(id);
    //         const jsVal = sessionStorage.getItem('editor-js');
    //         const combined = `const data = ${jsonVal};\n ${jsVal}`;
    //         sessionStorage.setItem('editor-js', combined);
    //         jsModel.setValue(sessionStorage.getItem('editor-js'));
    //     }
    //     const value = editor.getValue()
    //     sessionStorage.setItem(id, value);
    // }

    function evalCode(id) {
      const outputBox = document.getElementById('output');
      const value = sessionStorage.getItem(id)
      const output = eval(value);
      outputBox.innerHTML = '<span class="text-success font-weight-bold">Output</span><br>' + output + '<br>';
    }
  }
}
