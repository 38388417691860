import { Controller } from '@hotwired/stimulus'
import Tagify from '@yaireo/tagify';

export default class extends Controller {
  static values = { tags: String, readOnly: Boolean, delimiter: String,
                    asyncLoad: Boolean, resourceTypeId: String }
  static targets = ["inputTag", "inputValue"]

  connect() {
    let delimiter = this.delimiterValue;
    if (!delimiter) { delimiter = ',' }
    let tags = []
    if (this.tagsValue){
      tags = JSON.parse(this.tagsValue);
    }
    const tagify = new Tagify(this.inputTagTarget, {
      whitelist: tags,
      delimiters: delimiter,
      dropdown: {
        maxItems: 20,           // <- mixumum allowed rendered suggestions
        classname: "tags-look", // <- custom classname for this dropdown, so it could be targeted
        enabled: 0,             // <- show suggestions on focus
        closeOnSelect: false    // <- do not hide the suggestions dropdown once an item has been selected
      },
    });
    if (tagify && tagify.settings){
      tagify.setReadonly(this.readOnlyValue);
    }
    this.tagify = tagify;
    this.tagify.on('remove', (e) => {
      let item = e.detail.data;
      let ids = this.inputValueTarget.value.split(delimiter);
      ids = ids.filter((id) => id !== item?.id?.toString() );
      this.inputValueTarget.value = ids.join(delimiter);
    });
    if (this.asyncLoadValue){
      this.tagify.on('input', this.onInput.bind(this));
    }
  }

  async onInput(e) {
    const searchQuery = e.detail.value;
    const resourceTypeId = this.resourceTypeIdValue

    if (searchQuery.length < 1) return;

    const response = await fetch(`/manager/resource_types/${resourceTypeId}/suggestions?query=${searchQuery}`);
    const suggestions = await response.json();
    this.tagify.settings.whitelist = suggestions;
  }

  disconnect(){
    if (this.tagify){
      this.tagify.destroy();
    }
  }

  changeValue(e){
    let delimiter = this.delimiterValue;
    if (!delimiter) { delimiter = ',' }
    if (e.target.value){
      const values = JSON.parse(e.target.value);
      const valueIds = values.map((value) => value?.id?.toString());
      this.inputValueTarget.value = valueIds.join(delimiter);
    }
  }
}
