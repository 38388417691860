import { Application } from 'stimulus';
import Sortable from "stimulus-sortable"
import { Autocomplete } from 'stimulus-autocomplete'

const application = Application.start();
application.register("sortable", Sortable)
application.register('autocomplete', Autocomplete);


import AddonsController from "./manager/addons_controller"
application.register("manager--addons", AddonsController)

import AppBookingFieldsController from "./manager/app_booking_fields_controller"
application.register("manager--app-booking-fields", AppBookingFieldsController)

import AppCoverageFeatureGroupsController from "./manager/app_coverage_feature_groups_controller"
application.register("manager--app-coverage-feature-groups", AppCoverageFeatureGroupsController)

import AppCoverageFeaturesController from "./manager/app_coverage_features_controller"
application.register("manager--app-coverage-features", AppCoverageFeaturesController)

import AppsController from "./manager/apps_controller"
application.register("manager--apps", AppsController)

import BookingFieldsController from "./manager/booking_fields_controller"
application.register("manager--booking-fields", BookingFieldsController)

import BootstrapDatepickerController from "./manager/bootstrap_datepicker_controller"
application.register("manager--bootstrap-datepicker", BootstrapDatepickerController)

import BootstrapSelectController from "./manager/bootstrap_select_controller"
application.register("manager--bootstrap-select", BootstrapSelectController)

import CodeEditorController from "./manager/code_editor_controller"
application.register("manager--code-editor", CodeEditorController)

import CoverageFeaturesController from "./manager/coverage_features_controller"
application.register("manager--coverage-features", CoverageFeaturesController)

import DocumentManagementController from "./manager/document_management_controller"
application.register("manager--document-management", DocumentManagementController)

import HeaderController from "./manager/header_controller"
application.register("manager--header", HeaderController)

import InsurerContactsController from "./manager/insurer_contacts_controller"
application.register("manager--insurer-contracts", InsurerContactsController)

import InsurersController from "./manager/insurers_controller"
application.register("manager--insurers", InsurersController)

import JsonEditorController from "./manager/json_editor_controller"
application.register("manager--json-editor", JsonEditorController)

import MarkdownEditorController from "./manager/markdown_editor_controller"
application.register("manager--markdown-editor", MarkdownEditorController)

import PagerInfoController from "./manager/pager_info_controller"
application.register("manager--pager-info", PagerInfoController)

import PremiumsController from "./manager/premiums_controller"
application.register("manager--premiums", PremiumsController)

import ProductHighlightsController from "./manager/product_highlights_controller"
application.register("manager--product-highlights", ProductHighlightsController)

import ResourceCategoriesController from "./manager/resource_categories_controller"
application.register("manager--resource-categories", ResourceCategoriesController)

import RolesController from "./manager/roles_controller"
application.register("manager--roles", RolesController)

import SaveFormController from "./manager/save_form_controller"
application.register("manager--save-form", SaveFormController)

import SearchFieldsController from "./manager/search_fields_controller"
application.register("manager--search-fields", SearchFieldsController)

import SearchFormsController from "./manager/search_forms_controller"
application.register("manager--search-forms", SearchFormsController)

import TagifyController from "./manager/tagify_controller"
application.register("manager--tagify", TagifyController)

import TariffsController from "./manager/tariffs_controller"
application.register("manager--tariffs", TariffsController)

import TooltipController from "./manager/tooltip_controller"
application.register("manager--tooltip", TooltipController)

import UsersController from "./manager/users_controller"
application.register("manager--users", UsersController)
