import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import './turbo'
import './controllers';

// NOTE: while not all app migrated to turbo
window.Turbo.session.drive = false

Rails.start();
window.Rails = Rails;
ActiveStorage.start();


// this is a fix when user click back browser button where bootstrap-select elements, then click forward button ;)
window.$(document).on('turbolinks:before-cache', function() {
  window.$('.selectpicker').each(function(index, el){
    const $picker = $(el);
    const picker = $picker.data('selectpicker');
    if (picker) {
        picker.destroy();
        $picker.addClass('selectpicker');
    }
  });
});

import "trix"
import "@rails/actiontext"
