import { Controller } from '@hotwired/stimulus'

const DEFAULT_TOOLBAR_OPTIONS = [
  'bold', 'italic', 'strikethrough', 'heading',
  'code', 'quote', 'unordered-list', 'ordered-list',
  'link', 'image', 'horizontal-rule', 'preview', 'guide'
];

export default class extends Controller {
  connect() {
    let options = {
      element: document.getElementById(this.element.id),
      spellChecker: false
    };

    if (this.element.disabled){
      options.toolbar = ["preview"];
      options.status = false;
    } else {
      options.toolbar = DEFAULT_TOOLBAR_OPTIONS;
    }
    const simplemde = new SimpleMDE(options);
    simplemde.codemirror.options.readOnly = this.element.disabled;
  }
}
