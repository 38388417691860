import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    this.showHideTooltips()
  }

  showHideTooltips(){
    document.getElementsByClassName('explanation-icon').forEach(function(icon) {
      if (icon.getElementsByClassName('explanation-tooltip')[0].textContent.trim() === "") {
        icon.classList.add('d-none')
      } else {
        icon.classList.remove('d-none')
      }
    })
  }
}
