import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    "valuesWrapper",
    "fieldCollectionType"
  ]

  static values = { valueAddUrl: String }

  connect(){
  }

  updateSelect(event){
    if (event.detail.name == "addon[value_type]"){
      if(event.detail.value == "multi"){
        this.valuesWrapperTarget.classList.remove("d-none");
      }
      else {
        this.valuesWrapperTarget.classList.add("d-none");
      }
    }
  }

  addValue(event){
    event.preventDefault();
    event.stopPropagation();
    const headers = { "X-Requested-With": "XMLHttpRequest" }
    const url = new URL(this.valueAddUrlValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        let elementsCount = this.valuesWrapperTarget.childElementCount;
        let newIndex =  elementsCount === 0 ? 0 : elementsCount + 1
        html = html.replaceAll("0", newIndex.toString());
        this.valuesWrapperTarget.insertAdjacentHTML('beforeend', html);
      });
  }

  removeFieldValue(event){
    event.preventDefault();
    event.stopPropagation();
    let parentElement = event.currentTarget.parentElement.parentElement.parentElement;
    let nextElement = parentElement.nextElementSibling;

    if (nextElement && nextElement.tagName == "INPUT" && nextElement.type == "hidden"){
      const id = nextElement.value;
      const name = nextElement.name.replace("id", "_destroy")
      const removeTag = "<input type='hidden' name='" + name + "' value='1'>";
      nextElement.insertAdjacentHTML('afterend', removeTag);
    }
    parentElement.remove();
  }
}
