import { Controller } from '@hotwired/stimulus'
import { validateFields } from '../../utils/form_utils';

export default class extends Controller {
    static targets = [
        "fieldType", "conditions", "values",
        "fieldValueTypeWrapper", "valuesWrapper",
        "fieldCollectionType", "fieldTypeWrapper",
        "repeatable", "repeatFieldIdWrapper",
        "booleanDefaultValueWrapper", "defaultValueWrapper",
        "resourceTypeWrapper", "disabledFieldValues", "disabledOptionsWrapper",
        "defaultValue", "defaultValueBoolean"
    ]

    static values = { disabledUrl: String }

    connect() {
        validateFields();
        window.$(".create-booking-field-link").on('click', (e) => {
            e.stopPropagation();
        });
    }

    selectBookingField(e) {
        const bookingFieldId = e.currentTarget.dataset['autocompleteValue'];
        const headers = { "X-Requested-With": "XMLHttpRequest" }
        console.log(this.disabledUrlValue);
        const url = new URL(this.disabledUrlValue, window.location.href)
        fetch(url.toString() + "?booking_field_id=" + bookingFieldId, { headers })
        .then(response => response.text())
        .then(html => {
            if (html === ""){
                return this.disabledOptionsWrapperTarget.classList.add("d-none");
            }
            this.disabledFieldValuesTarget.innerHTML = html;
            this.disabledOptionsWrapperTarget.classList.remove("d-none");
        });
    }

    resourceTypeDisplay() {
        if(this.fieldCollectionTypeTarget.value === 'resource_type') {
            this.resourceTypeWrapperTarget.classList.remove("d-none");
        } else {
            this.resourceTypeWrapperTarget.classList.add("d-none");
        }
    }

    repeatableChanged(value){
        if(value){
            this.repeatFieldIdWrapperTarget.classList.remove("d-none");
        }
        else{
            this.repeatFieldIdWrapperTarget.classList.add("d-none");
        }
    }

    toggleRepeatable(event){
        this.repeatableChanged(event.target.checked);
    }

    populateDisabledValues(value){
        const headers = { "X-Requested-With": "XMLHttpRequest" }
        const url = new URL(this.disabledCollectionOptionsUrlValue, window.location.href)
        const bookingCollectionName = value;
        const bookingFieldId = document.getElementById("booking_field_id").value;
        fetch(url.toString() + "?booking_collection_name=" + bookingCollectionName + "&booking_field_id=" + bookingFieldId, { headers })
            .then(response => response.text())
            .then(html => {
                this.disabledFieldValuesTarget.innerHTML = html;
            });
    }

    addFieldValue(event){
        event.preventDefault();
        event.stopPropagation();
        const headers = { "X-Requested-With": "XMLHttpRequest" }
        const url = new URL(this.fieldValueAddUrlValue, window.location.href)
        const params = new URLSearchParams(url.search.slice(1))
        fetch(url.toString(), { headers })
            .then(response => response.text())
            .then(html => {
                let elementsCount = this.valuesWrapperTarget.childElementCount;
                let newIndex =  elementsCount === 0 ? 0 : elementsCount + 1
                html = html.replaceAll("0", newIndex.toString());
                this.valuesWrapperTarget.insertAdjacentHTML('beforeend', html);
            });
    }

    removeFieldValue(event){
        event.preventDefault();
        event.stopPropagation();
        let parentElement = event.currentTarget.parentElement.parentElement.parentElement;
        let nextElement = parentElement.nextElementSibling;

        if (nextElement && nextElement.tagName == "INPUT" && nextElement.type == "hidden"){
            const id = nextElement.value;
            const name = nextElement.name.replace("id", "_destroy")
            const removeTag = "<input type='hidden' name='" + name + "' value='1'>";
            nextElement.insertAdjacentHTML('afterend', removeTag);
        }
        parentElement.remove();
    }

    updateSelect(event){
        switch(event.detail.name){
            case "booking_field[field_type]":
                this.bookingFieldTypeChanged(event.detail.value);
                break;
            case "booking_field[type]":
                this.typeChanged(event.detail.value);
                break;
        }
    }

    typeChanged(value) {
        switch(value) {
            case "custom":
                this.showFieldType();
                this.hideDisabledValues();
                break;
            default:
                this.populateDisabledValues(value);
                this.hideFieldType();
                this.showDisabledValues();
        }
    }

    bookingFieldTypeChanged(value, init = false){
        switch(value){
            case "collection":
                this.showValues();
                break;
            case "radio":
                this.showValues();
                break;
            case "boolean":
                this.showBooleanDefaultValue();
                break;
            default:
                this.hideValues();
                break;
        }
    }

    showDisabledValues(){
        this.disabledOptionsWrapperTarget.classList.remove('d-none');
    }

    hideDisabledValues(){
        this.disabledOptionsWrapperTarget.classList.add('d-none');
    }

    hideBooleanDefaultValue(){
        document.getElementsByName("booking_field[default_value]").forEach(function(el) {
            el.setAttribute('name', "boolean_booking_field[default_value]");
        });
        this.defaultValueBooleanTarget.name = ""
        this.defaultValueTarget.name = "booking_field[default_value]";
    }

    showBooleanDefaultValue(){
        this.defaultValueTarget.name = "";
        document.getElementsByName("boolean_booking_field[default_value]").forEach(function(el) {
            el.setAttribute('name', "booking_field[default_value]");
        });
        this.defaultValueWrapperTarget.classList.add("d-none");
        this.booleanDefaultValueWrapperTarget.classList.remove("d-none");
    }

    hideFieldType(){
        this.fieldTypeWrapperTarget.classList.add("d-none");
    }
    showFieldType(){
        this.fieldTypeWrapperTarget.classList.remove("d-none");
    }

    hideValues(){
        this.hideBooleanDefaultValue();
        this.defaultValueWrapperTarget.classList.remove("d-none");
        this.booleanDefaultValueWrapperTarget.classList.add("d-none");
        this.valuesWrapperTarget.classList.add("d-none");
    }

    showValues(){
        this.hideBooleanDefaultValue();
        this.defaultValueWrapperTarget.classList.remove("d-none");
        this.booleanDefaultValueWrapperTarget.classList.add("d-none");
        this.valuesWrapperTarget.classList.remove("d-none");
    }
}
