import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["formDetailsTabToggleable", "formPermissionsTabToggleable"]
  connect() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if (urlParams.get('tab')){

    }
    else {
      this.changeFormToDetailsTab()
    }
  }

  changeFormToDetailsTab(){
    this.formDetailsTabToggleableTargets.forEach((target) => {
      if (!target.classList.contains('active')){
        target.classList.toggle('active')
      }
    })
    this.switchTabOff(this.formPermissionsTabToggleableTargets)
  }

  changeFormToPermissionsTab(){
    this.switchTabOff(this.formDetailsTabToggleableTargets)
    this.formPermissionsTabToggleableTargets.forEach((target) => {
      if (!target.classList.contains('active')){
        target.classList.toggle('active')
      }
    })
  }

  switchTabOff(targets){
    targets.forEach((target) => {
      target.classList.remove('active')
    })
  }
}
