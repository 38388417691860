import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
    static targets = [
        "resourcesWrapper"
    ]

    static values = { resourceComponentUrl: String, resourceCategoryId: String }

    updateSelect(event){
        switch(event.detail.name){
            case "resource_category[resource_type_id]":
                this.renderResources(event.detail.value);
        }
    }

    renderResources(typeId){
        const headers = { "X-Requested-With": "XMLHttpRequest" }
        let url = new URL(this.resourceComponentUrlValue, window.location.href);
        url = url.toString() + "?resource_type_id=" + typeId + "&resource_category_id=" + this.resourceCategoryIdValue;
        fetch(url, { headers })
            .then(response => response.text())
            .then(html => {
                this.resourcesWrapperTarget.innerHTML = html;
            });
    }

}
