import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  updateSelect(event){
    switch(event.detail.name){
      case "pagination_per_page":
        this.updatePaginationInfo(event.detail.value);
    }
  }

  updatePaginationInfo(value){
    let url = new URL(location.href)

    url.searchParams.delete("per_page")

    url.searchParams.set("per_page", value);

    location.replace(url);
  }
}
