import { Controller } from '@hotwired/stimulus'
import { validateFields } from '../../utils/form_utils';

export default class extends Controller {
  static targets = ["cloneModal", "cloneForm"]

  initialize() {
    validateFields();
  }

  openCloneModal(e){
    e.preventDefault();
    let group = event.currentTarget.dataset.groupId;
    let appId = event.currentTarget.dataset.appId;
    let url = "/manager/apps/" + appId + "/app_coverage_feature_groups/" + group + "/clone"
    this.cloneFormTarget.action = url;
    this.cloneModalTarget.classList.add("d-block");
  }

  closeCloneModal(e){
    e.preventDefault();
    this.cloneModalTarget.classList.remove('d-block');
  }
}
