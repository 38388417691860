import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { schema: String }
  generateUrl(){
    let url = window.location.pathname;
    if (url.substring(url.length-5, url.length) === "/edit"){
      url = url.replace("/edit", "");
    }

    if (url.substring(url.length-4, url.length) === "/new"){
      url = url.replace("/new", "");
    }
    return url;
  }

  schema(){
    return {
      premiums: {
        type: "array",
        title: "Premiums",
        format: "grid",
        items: {
          headerTemplate: "price - {{self.price}}",
          $ref: this.generateUrl() + "/" + this.schemaValue + ".json",
          title: "Premium",
          options: {
            collapsed: false
          }
        }
      },
      base: {
        $ref: this.generateUrl() + "/" + this.schemaValue + ".json",
        type: "array",
        format: "grid"
      }
    }
  }

  connect() {
    let element = document.getElementById(this.element.id);
    let value = JSON.parse(element.dataset["json"]);

    var editor = new JSONEditor(element, {
      // Enable fetching schemas via ajax
      ajax: true,
      theme: 'bootstrap4',
      disable_collapse: false,
      disable_edit_json: true,
      disable_properties: true,

      schema: (this.schema()[this.schemaValue] || this.schema()["base"]),


      // Seed the form with a starting value
      startval: value
    });


    editor.on('ready',() => {
      if (element.dataset['readOnly'] == "true"){
        editor.disable();
      }
    });

    editor.on('change', () => {
      this.updateInputValue(editor, element.dataset["valueElementId"])
    });

    let form = element.closest('form');
    if (form) {
      form.addEventListener('submit', () => {
        this.updateInputValue(editor, element.dataset["valueElementId"])
      });
    }
  }

  updateInputValue(editor, element) {
    let input = document.getElementById(element);
    input.value = JSON.stringify(editor.getValue());
  }
}
