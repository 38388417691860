import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    const orientation = this.element.dataset.orientation || 'auto top';

    window.$(this.element).datepicker({
      todayHighlight: true,
      orientation: orientation,
      format: 'dd.mm.yyyy'
    });
  }
}
