import { Controller } from '@hotwired/stimulus'
import { validateFields } from '../../utils/form_utils';

export default class extends Controller {
  static targets = ["multipleValuesWrapper", "multipleValues", "fieldValueType", "unitWrapper"]

  connect() {
    validateFields();
    if (this.targets.find("fieldValueType")){
      this.handleChangeValueType(this.fieldValueTypeTarget.value);
    }
  }

  updateSelect(event){
    switch(event.detail.name){
      case 'coverage_feature[field_type]':
        this.handleChangeValueType(event.detail.value);
        break;
      case 'coverage_feature[value_type]':
        if (event.detail.value == 'number'){
          this.unitWrapperTarget.classList.remove('d-none');
        }
        else {
          this.unitWrapperTarget.classList.add('d-none');
        }
        break;
    }
  }

  handleChangeValueType(fieldType){
    if (fieldType === 'custom'){
      this.multipleValuesTarget.value = "0";
      this.multipleValuesWrapperTarget.classList.add("d-none");
    }
    else {
      this.multipleValuesTarget.value = "1";
      this.multipleValuesWrapperTarget.classList.remove("d-none");
    }
  }

}
