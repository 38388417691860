export function validateFields(){
  window.$(".simple_form.form input.required").change((e) => {
    let $el = window.$(e.target);
    if ($el.val() !== ""){
      $el.removeClass("is-invalid");
      if(!$el.hasClass("is-valid")){
        $el.addClass("is-valid");
      }
    }
    else {
      $el.removeClass("is-valid");
      if(!$el.hasClass("is-invalid")){
        $el.addClass("is-invalid");
      }
    }
  });
}
