import { Controller } from '@hotwired/stimulus'
import { validateFields } from '../../utils/form_utils';

export default class extends Controller {
  connect() {
    validateFields();
  }

  static targets = ['defaultPaymentIntervalWrapper', 'paymentIntervalWrapper'];

  toggleAllowDifferentPaymentPeriod(e){
    event.preventDefault();
    if (event.currentTarget.checked){
      this.defaultPaymentIntervalWrapperTarget.classList.remove('d-none');
      this.paymentIntervalWrapperTarget.classList.remove('d-none');
    }
    else {
      this.defaultPaymentIntervalWrapperTarget.classList.add('d-none');
      this.paymentIntervalWrapperTarget.classList.add('d-none');
    }
  }
}
