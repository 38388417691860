import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "premiumTab" ]
  static values = [  ]

  showTabContent(e) {
    if (!e.currentTarget.classList.contains('collapsed')) { return }

    const premiumIndex = e.currentTarget.dataset.index
    setTimeout(() => {
      const editor = `<div class='col-9'><div id='editor-js' class='editor-js col m-0 p-0' language='json' data-controller='manager--code-editor' style='min-height:400px;' data-value-element-id='premiums_payload_${premiumIndex}' data-action='update-editor-size@window->manager--code-editor#resize'></div></div>`
      document.getElementById(`result_${premiumIndex}`).innerHTML = editor
    }, 1000)
  }
}
