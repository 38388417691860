import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    selectPlaceholder: String,
  }

  initialize() {
    this.changePlaceholder()
    if(!window.$(this.element).parent().hasClass('bootstrap-select')) {
      if (window.$(this.element).data('dropup-auto')){
        window.$(this.element).selectpicker({dropupAuto: true})
      }
      else {
        window.$(this.element).selectpicker({dropupAuto: false});
      }
      window.$(this.element).on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        const event = new CustomEvent("update-select", {
          detail: {
            name: e.target.name, value: e.target.value
          }
        });
        window.dispatchEvent(event)
      });
    }
  }

  changePlaceholder() {
    const tariffForm = document.getElementById('tariffIndexPage')
    if (!tariffForm || !this.selectPlaceholderValue) { return }

    const els = document.querySelectorAll('.filter-option-inner-inner')
    els.forEach(element => {
      const closestSelect = element.closest('.bootstrap-select').querySelector('select');
      if(closestSelect.value == '') { element.textContent = this.selectPlaceholderValue }
    })
  }
}
