import { Controller } from '@hotwired/stimulus'
import { validateFields } from '../../utils/form_utils';

export default class extends Controller {
  static targets = [
    "fieldType", "fieldValueType", "conditions", "defaultValue", "values", "coverageFeature",
    "fieldValueTypeWrapper", "conditionsWrapper", "valuesWrapper", "coverageFeatureWrapper",
    "defaultValueWrapper", "defaultValueFieldContent",
    "disabledValueWrapper", "disabledValueFieldContent"
  ]

  static values = { fieldValueAddUrl: String, defaultFieldValueUrl: String, disabledFieldValueUrl: String }

  connect() {
    validateFields();
    if (this.targets.find("fieldValueType")){
      this.searchFieldTypeChanged(this.fieldTypeTarget.value, true);
    }
  }

  addFieldValue(event){
    event.preventDefault();
    event.stopPropagation();
    const headers = { "X-Requested-With": "XMLHttpRequest" }
    const url = new URL(this.fieldValueAddUrlValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        let elementsCount = this.valuesWrapperTarget.childElementCount;
        let newIndex =  elementsCount === 0 ? 0 : elementsCount + 1
        html = html.replaceAll("0", newIndex.toString());
        this.valuesWrapperTarget.insertAdjacentHTML('beforeend', html);
      });
  }

  removeFieldValue(event){
    event.preventDefault();
    event.stopPropagation();
    let parentElement = event.currentTarget.parentElement.parentElement.parentElement;
    let nextElement = parentElement.nextElementSibling;

    if (nextElement && nextElement.tagName == "INPUT" && nextElement.type == "hidden"){
      const id = nextElement.value;
      const name = nextElement.name.replace("id", "_destroy")
      const removeTag = "<input type='hidden' name='" + name + "' value='1'>";
      nextElement.insertAdjacentHTML('afterend', removeTag);
    }
    parentElement.remove();
  }

  updateSelect(event){
    switch(event.detail.name){
      case "search_field[type]":
        this.searchFieldTypeChanged(event.detail.value);
        break;
      case "search_field[value_type]":
        this.searchFiledValueTypeChanged(event.detail.value);
        break;
      case "search_field[app_coverage_feature_id]":
        this.populateDefaultValue(event.detail.value);
        this.populateDisabledValue(event.detail.value)
        break;
    }
  }

  searchFieldTypeChanged(value, init = false){
    switch(value){
      case "coverage_feature":
        this.showCoverageFeature();
        this.hideValues();
        this.hideFieldType();
        this.showDefaultValue();
        break;
      case "custom_field":
        this.showFieldType();
        this.showDefaultValue();
        this.hideCoverageFeature();
        this.hideValues();
        break;
      case "custom_field_with_condition":
        this.showFieldType();
        this.showDefaultValue();
        this.hideCoverageFeature();
        this.hideValues();
        break;
      case "custom_field_with_coverage_feature":
        this.showFieldType();
        this.showDefaultValue(value);
        this.showCoverageFeature();
        this.hideValues();
        break;
    }
    if (!init){
      let fieldValue = document.getElementById("search_field_app_coverage_feature_id").value;
      if (value !== "coverage_feature"){
        fieldValue = null;
      }
      this.populateDefaultValue(fieldValue);
    }
    if (this.targets.find("fieldValueType")){
      this.searchFiledValueTypeChanged(this.fieldValueTypeTarget.value);
    }
  }

  populateDefaultValue(value){
    const headers = { "X-Requested-With": "XMLHttpRequest" }
    const url = new URL(this.defaultFieldValueUrlValue, window.location.href)
    const app_coverage_feature_id = value;
    fetch(url.toString() + "?type=coverage_feature&app_coverage_feature_id=" + app_coverage_feature_id, { headers })
      .then(response => response.text())
      .then(html => {
        if (this.hasDefaultValueFieldContentTarget){
          this.defaultValueFieldContentTarget.innerHTML = html;
        }
      });
  }

  populateDisabledValue(value){
    const headers = { "X-Requested-With": "XMLHttpRequest" }
    const url = new URL(this.disabledFieldValueUrlValue, window.location.href)
    const app_coverage_feature_id = value;
    fetch(url.toString() + "?type=coverage_feature&app_coverage_feature_id=" + app_coverage_feature_id, { headers })
      .then(response => response.text())
      .then(html => {
        if (this.hasDisabledValueFieldContentTarget){
          this.disabledValueFieldContentTarget.innerHTML = html;
        }
      });
  }

  searchFiledValueTypeChanged(value){
    switch(value){
      case "text":
      case "number":
      case "date":
        this.hideValues();
        break;
      case "collection":
        this.showValues();
        break;
    }
  }

  showFieldType(){
    this.fieldValueTypeWrapperTarget.classList.remove("d-none");
  }

  hideFieldType(){
    this.fieldValueTypeWrapperTarget.classList.add("d-none");
  }

  hideCoverageFeature(){
    this.coverageFeatureWrapperTarget.classList.add("d-none");
  }

  showCoverageFeature(){
    this.coverageFeatureWrapperTarget.classList.remove("d-none");
  }

  hideValues(){
    this.valuesWrapperTarget.classList.add("d-none");
  }

  showValues(){
    this.valuesWrapperTarget.classList.remove("d-none");
  }

  hideDefaultValue(){
    if (this.hasDefaultValueWrapperTarget){
      this.defaultValueWrapperTarget.classList.add("d-none");
    }
    if (this.hasDisabledValueWrapperTarget){
      this.disabledValueWrapperTarget.classList.add("d-none");
    }
  }

  showDefaultValue(){
    if (this.hasDefaultValueWrapperTarget){
      this.defaultValueWrapperTarget.classList.remove("d-none");
    }
    if (this.hasDisabledValueWrapperTarget){
      this.disabledValueWrapperTarget.classList.remove("d-none");
    }
  }
}
