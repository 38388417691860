import { Controller } from '@hotwired/stimulus'
import Cookies from "js-cookie";

export default class extends Controller {
  static values = { componentId: String }

  static targets = [
    "quickUserToggleable",
    "asideToggleable",
    "asideButtonToggleable",
    "resourcesToggleable",
    "insurersToggleable",
    "appsToggleable",
    "toolsToggleable",
    "settingsToggleable",
    "quickPanelToggleable",
    "administrationToggleable"
  ]

  toggleQuickUser(){
    this.quickUserToggleableTarget.classList.toggle('offcanvas-on')
  }

  togglePanel() {
    const panel = document.getElementById("kt_quick_panel_" + this.componentIdValue)
    panel.classList.toggle('offcanvas-on')
    document.querySelector('.offcanvas-overlay').classList.toggle('d-none')

    if (panel.classList.contains('offcanvas-on')) {
      document.querySelector(".datatable-row-" + this.componentIdValue).style.backgroundColor = '#ECF9FF'
    } else {
      document.querySelector(".datatable-row-" + this.componentIdValue).style.backgroundColor = '#FFFFFF'
    }
  }

  toggleResources(){
    this.resourcesToggleableTarget.classList.toggle('menu-item-open')
    if(this.asideToggleableTarget.classList.contains('aside-minimize')){
      this.toggleAside();
    }
  }

  toggleAdministration(){
    this.administrationToggleableTarget.classList.toggle('menu-item-open')
    if(this.asideToggleableTarget.classList.contains('aside-minimize')){
      this.toggleAside();
    }
  }

  toggleInsurers(){
    this.insurersToggleableTarget.classList.toggle('menu-item-open')
    if(this.asideToggleableTarget.classList.contains('aside-minimize')){
      this.toggleAside();
    }
  }

  toggleApps(){
    this.appsToggleableTarget.classList.toggle('menu-item-open')
    if(this.asideToggleableTarget.classList.contains('aside-minimize')){
      this.toggleAside();
    }
  }

  toggleTools(){
    this.toolsToggleableTarget.classList.toggle('menu-item-open')
    if(this.asideToggleableTarget.classList.contains('aside-minimize')){
      this.toggleAside();
    }
  }

  toggleSettings(){
    this.settingsToggleableTarget.classList.toggle('menu-item-open')
    if(this.asideToggleableTarget.classList.contains('aside-minimize')){
      this.toggleAside();
    }
  }

  toggleAside(){
    if (Cookies.get("aside_minimize")){
      Cookies.remove("aside_minimize");
      this.asideToggleableTarget.classList.remove('aside-minimize');
      this.asideButtonToggleableTarget.classList.remove('active')
    }
    else {
      Cookies.set('aside_minimize', "1");
      this.asideToggleableTarget.classList.add('aside-minimize');
      this.asideButtonToggleableTarget.classList.add('active')
    }
  }
}
