import { Controller } from '@hotwired/stimulus'
import { validateFields } from '../../utils/form_utils';

export default class extends Controller {
  static values = {
    documentAddUrl: String, ratingAddUrl: String,
    calculatePremiumUrl: String, ratingCoverageFeature: String,
    paymentOptionAddUrl: String, addRatingConditionUrl: String,
    duration: Number
  }

  static targets = [
    "getCoverageFeatureComponentUrl", "coverageFeaturesWrapper", "documentsWrapper",
    "ratingsWrapper", "ratingsConditionsWrapper", "metadata", "deactivationField",
    "paymentOptionsWrapper", "addRatingButton", "backToOverviewButton",
    "moveVariantModal", "moveVariantForm", "batchPremiumsModal", "batchPremiumsForm",
    "tariffJson", "insurerJson", "searchRuleValidationLabel", "additionalFilters",
    "validationError", "alternativeCreditor", "alternativeCreditorId", "formulaTabLink",
    "alternativeCreditorAddress", "alternativeCreditorName", "lookupTableTabLink",
    "columsSettings", "ibanField", "premiumLookupTableTab", "premiumFormulaTab",
    "policyEmailText", "appCoverageFeatureValue", "appCoverageFeatureValuesSelect",
    "tariffsCount", "linkoutUrl", "groupTitle"
  ]

  connect() {
    validateFields();
    if (this.targets.find("alternativeCreditor")){
      this.alternativeCreditorChanged(this.alternativeCreditorTarget.checked);
    }
    this.showFilters()
  }

  updateSelect(e){
    if (/tariff_ratings_attributes/.test(e.detail.name) && /app_coverage_feature_id/.test(e.detail.name)){
      this.updateRatingCoverageFeatureValues(e.detail);
    }
  }

  updateRatingCoverageFeatureValues(detail){
    const regex = /\[(\d+)\]/;
    const matches = detail.name.match(regex);
    if (detail.value == ""){
      document.getElementById("app_coverage_feature_values_" + matches[1]).innerHTML = "";
      return;
    }

    const headers = { "X-Requested-With": "XMLHttpRequest" }
    const url = new URL(this.ratingCoverageFeatureValue + "?app_coverage_feature_id=" + detail.value, window.location.href)
    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        html = html.replaceAll("0", matches[1].toString());
        document.getElementById("app_coverage_feature_values_" + matches[1]).innerHTML = html;
      });
  }

  changeLinkoutSwitch(e){
    if (e.currentTarget.checked){
      this.linkoutUrlTarget.classList.remove('d-none');
    }
    else {
      this.linkoutUrlTarget.classList.add('d-none');
    }

  }

  changeSendPolicyToCustomer(e){
    if (e.currentTarget.checked){
      this.policyEmailTextTarget.classList.remove('d-none');
    }
    else {
      this.policyEmailTextTarget.classList.add('d-none');
    }
  }

  selectAll(e) {
    const checked = e.target.checked;
    const checkboxes = new Array(...this.element.querySelectorAll('#indexTariffsTable input[type=checkbox]'))
    checkboxes.forEach((el) => { el.checked = checked })

    if (checked) {
      this.tariffsCountTarget.textContent = checkboxes.length
      $('#kt_datatable_group_action_form').slideDown()
    } else {
      $('#kt_datatable_group_action_form').slideUp()
    }
  }

  indexSelect() {
    const checkboxes = new Array(...this.element.querySelectorAll('#indexTariffsTable input[type=checkbox]:checked'))

    if (checkboxes.length > 0) {
      this.tariffsCountTarget.textContent = checkboxes.length
      $('#kt_datatable_group_action_form').slideDown()
    } else {
      $('#kt_datatable_group_action_form').slideUp()
    }
  }

  buildPreview(event) {
    event.preventDefault()
    event.stopPropagation()

    const checkboxes = this.element.querySelectorAll('#indexTariffsTable input[type=checkbox]:checked')

    let url = new URL(event.currentTarget.href);

    let ids = []
    checkboxes.forEach((checkbox) => {
      ids.push(checkbox.value)
    });

    fetch(url.toString() + "&ids=" + ids.join(","), {
      method: "GET",
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Accept': 'text/vnd.turbo-stream.html',
      }
    })
      .then((r) => r.text())
      .then((html) => {
        Turbo.renderStreamMessage(html)
      })
  }

  batchAction(event) {
    event.preventDefault()
    event.stopPropagation()

    const checkboxes = this.element.querySelectorAll('#indexTariffsTable input[type=checkbox]:checked')

    let url = new URL(event.currentTarget.href);

    let formData = new FormData();
    checkboxes.forEach((checkbox) => {
      formData.append("ids[]", checkbox.value);
    });

    fetch(url.toString(), {
      method: "POST",
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }).then(response => response.text())
      .then(html => {
        window.location.reload()
    })
  }

  showDeactivationModal(event) {
    event.preventDefault()
    event.stopPropagation()

    const checkboxes = new Array(...this.element.querySelectorAll('#indexTariffsTable input[type=checkbox]:checked'))
    const ids = checkboxes.map(checkbox => checkbox.value);

    const link = event.currentTarget
    const url = new URL(link.href);

    ids.forEach(id => url.searchParams.append('ids[]', id));

    fetch(url.toString(), {
      method: "GET",
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
      }
    }).then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
    })
  }

  changePremiumType(e){
    e.preventDefault();
    let premiumType = e.currentTarget.dataset['premiumType'];
    if (premiumType == 'formula'){
      this.premiumFormulaTabTarget.classList.remove('d-none');
      if (this.targets.find('premiumLookupTableTab')){
        this.premiumLookupTableTabTarget.classList.add('d-none');
      }
      if (this.targets.find('lookupTableTabLink')){
        this.lookupTableTabLinkTarget.classList.remove('menu-item-active');
      }
      this.formulaTabLinkTarget.classList.add('menu-item-active');
      const event = new CustomEvent("update-editor-size", {});
      window.dispatchEvent(event);
      return;
    }

    if (premiumType == 'lookup_table'){
      this.premiumFormulaTabTarget.classList.add('d-none');
      if(this.targets.find('premiumLookupTableTab')){
        this.premiumLookupTableTabTarget.classList.remove('d-none');
      }
      if (this.targets.find('lookupTableTabLink')){
        this.lookupTableTabLinkTarget.classList.add('menu-item-active');
      }
      this.formulaTabLinkTarget.classList.remove('menu-item-active');
      return;
    }
  }

  frontendMessage(message){
    if (!message.data.search_filters){
      return;
    }
    let searchFilters = JSON.parse(message.data.search_filters);
    this.calculatePremiums(searchFilters);
  }

  toggleMoreFiltersSection(){
    document.querySelector('#filters').classList.toggle("d-none");
  }

  showFilters() {
    const appField = document.getElementById('app_id');
    if (!appField || !appField.value) {
      return;
    }

    const url = new URL(window.location.href);

    url.searchParams.set('app_id', appField.value);

    const variantsEnabled = appField.getAttribute('data-variants') === 'true';

    let finalURL;
    if (variantsEnabled) {
      finalURL = `search_fields_collection${url.search}`;
    } else {
      finalURL = `tariffs/search_fields_collection${url.search}`;
    }

    fetch(finalURL, { method: 'GET' })
      .then(response => response.text())
      .then(html => {
        const element = document.querySelector('#filters');
        element.innerHTML = html;
      });
  }


  toggleColumsSettings(e){
    const toggleButton = e.target

    this.columsSettingsTarget.classList.toggle('d-none')

    if (this.columsSettingsTarget.classList.contains('d-none')) {
      toggleButton.textContent = toggleButton.dataset.showTextValue
    } else {
      toggleButton.textContent = toggleButton.dataset.hideTextValue
    }
  }

  alternativeCreditorChanged(value){
    if(value){
      this.alternativeCreditorIdTarget.classList.remove("d-none");
      this.alternativeCreditorNameTarget.classList.remove("d-none");
      this.alternativeCreditorAddressTarget.classList.remove("d-none");
    }
    else{
      this.alternativeCreditorIdTarget.classList.add("d-none");
      this.alternativeCreditorNameTarget.classList.add("d-none");
      this.alternativeCreditorAddressTarget.classList.add("d-none");
    }
  }

  toggleAlternativeCreditor(event) {
    this.alternativeCreditorChanged(event.target.checked);
  }

  calculatePremiums(filters){
    let premiumElement = document.getElementById('tariff_premium_formula');
    if (!premiumElement){
      premiumElement = document.getElementById('tariff_variant_premium_formula');
    }
    let formula = premiumElement.value;
    let iframe = document.getElementById('frontend_app_iframe').contentWindow;
    fetch(this.calculatePremiumUrlValue, { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ formula: formula, filters: filters })})
        .then(response => response.json())
        .then(json => {
          if (json.error){
            return alert(json.error);
          }
          iframe.postMessage(JSON.stringify(json), '*');
        });
  }

  removeCoverageFeature(event){
    event.preventDefault();
    event.stopPropagation();
    let parentElement = event.currentTarget.parentElement.parentElement;
    let id = event.currentTarget.dataset.coverageFeatureId;
    let hiddenElements = document.querySelectorAll("input[value='"+ id +"']");
    let idElement;
    hiddenElements.forEach(function(el) {
      if (el.name.match(/tariff_variant\[tariff_coverage_features_attributes\]\[\d+\]\[id\]/)){
        idElement = el;
      }
    });
    if (idElement){
      const name = idElement.name.replace("id", "_destroy")
      const removeTag = "<input type='hidden' name='" + name + "' value='1'>";
      parentElement.insertAdjacentHTML('afterend', removeTag);
    }
    parentElement.remove();
  }

  removeDocument(event){
    event.preventDefault();
    event.stopPropagation();
    let parentElement = event.currentTarget.parentElement.parentElement;
    let nextElement = parentElement.nextElementSibling;

    if (nextElement && nextElement.tagName == "INPUT" && nextElement.type == "hidden"){
      const id = nextElement.value;
      const name = nextElement.name.replace("id", "_destroy")
      const removeTag = "<input type='hidden' name='" + name + "' value='1'>";
      nextElement.insertAdjacentHTML('afterend', removeTag);
    }
    parentElement.remove();
  }

  removePaymentOption(event){
    event.preventDefault();
    event.stopPropagation();
    let parentElement = event.currentTarget.parentElement.parentElement.parentElement;
    let nextElement = parentElement.nextElementSibling;

    if (nextElement && nextElement.tagName == "INPUT" && nextElement.type == "hidden"){
      const id = nextElement.value;
      const name = nextElement.name.replace("id", "_destroy")
      const removeTag = "<input type='hidden' name='" + name + "' value='1'>";
      nextElement.insertAdjacentHTML('afterend', removeTag);
    }
    parentElement.remove();
  }

  removeRating(event){
    event.preventDefault();
    event.stopPropagation();
    let parentElement = event.currentTarget.parentElement.parentElement.parentElement;
    let nextElement = parentElement.nextElementSibling;

    if (nextElement && nextElement.tagName == "INPUT" && nextElement.type == "hidden"){
      const id = nextElement.value;
      const name = nextElement.name.replace("id", "_destroy")
      const removeTag = "<input type='hidden' name='" + name + "' value='1'>";
      nextElement.insertAdjacentHTML('afterend', removeTag);
    }
    parentElement.remove();
  }

  addDocument(event){
    event.preventDefault();
    event.stopPropagation();
    const headers = { "X-Requested-With": "XMLHttpRequest" }
    const url = new URL(this.documentAddUrlValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        let elementsCount = this.documentsWrapperTarget.childElementCount;
        let newIndex =  elementsCount === 0 ? 0 : elementsCount + 1
        html = html.replaceAll("0", newIndex.toString());
        this.documentsWrapperTarget.insertAdjacentHTML('beforeend', html);
      });
  }

  addPaymentOption(event){
    event.preventDefault();
    event.stopPropagation();
    const headers = { "X-Requested-With": "XMLHttpRequest" }
    const url = new URL(this.paymentOptionAddUrlValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        let elementsCount = this.paymentOptionsWrapperTarget.childElementCount;
        let newIndex =  elementsCount === 0 ? 0 : elementsCount + 1
        html = html.replaceAll("0", newIndex.toString());
        this.paymentOptionsWrapperTarget.insertAdjacentHTML('beforeend', html);
      });
  }

  addRating(event){
    event.preventDefault();
    event.stopPropagation();
    const headers = { "X-Requested-With": "XMLHttpRequest" }
    let url = new URL(this.ratingAddUrlValue, window.location.href).toString()
    const id = event.currentTarget.dataset.tariffRatingId
    if (id) { url = url + `?tariff_rating_id=${id}` }
    fetch(url, { headers })
      .then(response => response.text())
      .then(html => {
        this.ratingsWrapperTarget.innerHTML = html
        if (this.hasAddRatingButtonTarget) { this.addRatingButtonTarget.style.display = 'none' }
        if (this.hasBackToOverviewButtonTarget) { this.backToOverviewButtonTarget.style.display = 'block' }
      });
  }

  addRatingCondition(event) {
    event.preventDefault();
    event.stopPropagation();
    const headers = { "X-Requested-With": "XMLHttpRequest" }
    let url = new URL(this.addRatingConditionUrlValue, window.location.href).toString()
    const id = event.currentTarget.dataset.tariffRatingId
    if (id) { url = url + `?tariff_rating_id=${id}` }
    fetch(url, { headers })
      .then(response => response.text())
      .then(html => {
        let elementsCount = this.ratingsConditionsWrapperTarget.childElementCount;
        let newIndex =  elementsCount === 0 ? 0 : elementsCount
        html = html.replaceAll(/(\[|_)0(\]|_)/g, (_match, p1, p2) => `${p1}${newIndex}${p2}`);
        this.ratingsConditionsWrapperTarget.insertAdjacentHTML('beforeend', html);
      });
  }

  fetchCoverageFeatureValues(event) {
    const featureId = event.currentTarget.value
    if (!featureId) { return }

    const headers = { "X-Requested-With": "XMLHttpRequest" }
    const url = '/manager/tariff_ratings/app_coverage_feature_values' + `?app_coverage_feature_id=${featureId}`
    fetch(url, { headers })
    .then(response => response.json())
    .then(data => {
      const targetId = event.target.id;
      const prefix = targetId.match(/^tariff(?:_variant)?_tariff_ratings_attributes_\d+_conditions_attributes_\d+_/)[0];
      const selectId = prefix + 'value';

      const valueSelect = document.getElementById(selectId);
      if (valueSelect) {
        valueSelect.innerHTML = ""

        data.forEach(option => {
          const optionElement = document.createElement("option");
          optionElement.value = option[1];
          optionElement.textContent = option[0];
          valueSelect.appendChild(optionElement);
        });
        $(valueSelect).selectpicker('refresh');
      }
    });
  }

  removeRatingCondition(event){
    event.preventDefault();
    event.stopPropagation();
    let parentElement = event.currentTarget.parentElement.parentElement;
    let id = event.currentTarget.dataset.ratingConditionId;
    let hiddenElements = document.querySelectorAll("input[value='"+ id +"']");
    let idElement;
    hiddenElements.forEach(function(el) {
      if (el.name.match(/tariff(?:_variant)?\[tariff_ratings_attributes\]\[\d+\]\[conditions_attributes\]\[\d+\]\[id\]/)){
        idElement = el;
      }
    });
    if (idElement){
      const name = idElement.name.replace("id", "_destroy")
      const removeTag = "<input type='hidden' name='" + name + "' value='1'>";
      parentElement.insertAdjacentHTML('afterend', removeTag);
    }
    parentElement.remove();
  }

  openVariantModal(event){
    event.preventDefault();
    let tariffId = event.currentTarget.dataset.tariffId;
    let url = "/manager/tariffs/" + tariffId + "/create_variant"
    this.moveVariantFormTarget.action = url;
    this.moveVariantModalTarget.classList.add("d-block");
  }

  openBatchPremiumsModal(event){
    event.preventDefault();
    this.batchPremiumsModalTarget.classList.add("d-block");
  }

  closeBatchPremiumsModal(event){
    event.preventDefault();
    this.batchPremiumsModalTarget.classList.remove("d-block");
  }

  closeVariantModal(event){
    event.preventDefault();
    this.moveVariantModalTarget.classList.remove("d-block");
  }

  validateSearchRules(event, type){
    if(!type){
      event.preventDefault();
    }
    if (this.validateSearch()){
      this.searchRuleValidationLabelTarget.classList.remove("label-danger");
      this.searchRuleValidationLabelTarget.classList.add("label-success");
    }
    else {
      event.preventDefault();
      event.stopPropagation();
      this.searchRuleValidationLabelTarget.classList.remove("label-success");
      this.searchRuleValidationLabelTarget.classList.add("label-danger");
    }
  }

  submitForm(event){
    if (!this.targets.find("searchRuleValidationLabel")){
      return;
    }
    this.validateSearchRules(event, "form");
  }

  submitSearchForm(){
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.addSpiner()
      document.getElementById('tariffs-search-form').submit()
    }, this.durationValue)
  }

  addSpiner(){
    document.getElementsByClassName('card-custom')[0].classList.add('overlay', 'overlay-block')
    const form = document.getElementById('tariffIndexPage')
    form.classList.add('overlay-wrapper')
    const spinner = document.createElement('div')
    spinner.innerHTML = '<div class="spinner spinner-primary spinner-track spinner-lg"></div>'
    spinner.className = 'overlay-layer bg-dark-o-10'
    document.getElementsByClassName('card-body')[0].appendChild(spinner)
  }

  removeSpiner(){
    document.getElementsByClassName('card-custom')[0].classList.remove('overlay', 'overlay-block')
    const form = document.getElementById('tariffIndexPage')
    form.classList.remove('overlay-wrapper')
    const overlay = document.getElementsByClassName('overlay-layer')[0]
    overlay.remove()
  }

  validateSearch(){
    if (!this.targets.find("searchRule")) {
      return true;
    }
    try{
      let searchRuleValue = this.searchRuleTarget.value;
      let searchDataValue = JSON.parse(this.searchDataTarget.value);
      let tariffOBject = JSON.parse(this.tariffJsonTarget.value);
      let insurerObject = JSON.parse(this.insurerJsonTarget.value);
      eval(searchRuleValue);
      return window.covomoSearchValidation(searchDataValue, tariffOBject, insurerObject);
    }
    catch(e){
      this.validationErrorTarget.innerHTML = e;
      return false;
    }
  }

  changeStatusValue(e) {
    const statusSelect = e.currentTarget
    if (statusSelect.value == 'inactive') {
      this.deactivationFieldTarget.classList.remove('d-none')
    } else {
      this.deactivationFieldTarget.classList.add('d-none')
    }
  }

  showHideIbanField(e) {
    const typeSelect = e.currentTarget

    if (this.targets.find('ibanField')){
      if (typeSelect.value == '1') {
        this.ibanFieldTarget.classList.remove('d-none')
      } else {
        this.ibanFieldTarget.classList.add('d-none')
      }
    }
  }

  exportFile(e) {
    e.preventDefault();
    e.stopPropagation();

    const checkboxes = new Array(...this.element.querySelectorAll('#indexTariffsTable input[type=checkbox]:checked'))

    this.addSpiner()
    const ids = checkboxes.map(checkbox => checkbox.value);

    const link = e.currentTarget
    const url = new URL(link.href);

    ids.forEach(id => url.searchParams.append('ids[]', id));

    fetch(url.toString(), {})
    .then(response => response.blob())
    .then(data => {
      const url = window.URL.createObjectURL(data);
      window.location.assign(url)
      this.removeSpiner()
    })
  }

  calculateScore(event) {
    const eventValue = event.target.value

    // Do not calculate when character is '.' or ','
    const lastChar = eventValue.slice(-1)
    if (lastChar === '.' || lastChar === ',') {
      return
    }

    const parentDiv = event.target.closest('.app-coverage-feature-row')
    if (!parentDiv.querySelector('[data-target="scoreInput"]')) { return }

    let ratingValue;
    let colorValue;
    const featureId = event.target.dataset.id

    if (event.target.name.includes('rating')) {
      ratingValue = eventValue

      const radioButtons = parentDiv.querySelectorAll('[data-target="colorInput"]')

      for (const radioButton of radioButtons) {
        if (radioButton.checked) {
          colorValue = radioButton.value;
          break;
        }
      }
    } else {
      colorValue = eventValue
      ratingValue = parentDiv.querySelector('[data-target="ratingInput"]').value
    }

    console.log(ratingValue)
    console.log(colorValue)

    const href = '/manager/tariffs/calculate_feature_score'
    const formData = new FormData()

    formData.append('tariff_coverage_feature[id]', featureId)
    formData.append('tariff_coverage_feature[color]', colorValue)
    formData.append('tariff_coverage_feature[rating]', ratingValue)

    fetch(href, {
      method: 'POST',
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        const scoreField = parentDiv.querySelector('[data-target="scoreInput"]')
        scoreField.value = `${data.score} von ${data.max_score}`

        if (this.groupTitleTargets.length > 0) {
          this.fetchGroupScore(parentDiv)
        }
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  fetchGroupScore(parentDiv) {
    const href = '/manager/tariffs/calculate_group_score'
    const formData = new FormData()

    const groupId = parentDiv.querySelector('[data-target="groupIdInput"]').value
    if (!groupId) { return }

    const tabPanel = parentDiv.closest('.collapse')
    const scoreInputs = tabPanel.querySelectorAll('[data-target="scoreInput"]')
    scoreInputs.forEach((score) => {
      formData.append("tariff_coverage_feature[scores][]", score.value);
    });
    formData.append('tariff_coverage_feature[group_id]', groupId)


    fetch(href, {
      method: 'POST',
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        const groupTitle = this.groupTitleTargets.find(el => el.dataset.target === `#${tabPanel.id}`);
        if (groupTitle && data.name) {
          groupTitle.innerText = data.name
        }
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }
}
